import type { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import type { CargoSearchRequest } from '../../../Models/CargoTrackerRequest';
import type { SearchRequest } from '../../../../Surveillance/Models/ReportsRequest';
import type { KeyValue } from 'types/generic-type';
import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';

export const additionalSearchPropParser = (
  props: WorksheetMetaProps[]
): WorksheetMetaProps[] => props.map(p => {
  try {
    return typeof p.value === 'string' ? { ...p, value: JSON.parse(p.value as string) } : p;
  } catch {
    return p;
  }
});

const fieldsParser = (fields: SearchField[]): SearchField[] => fields;

const hydrator = (): WorksheetMetaProps[] => [];

export const cargoSearchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser,
  hydrator,
};

// Check if search object is empty (undefined elements and empty arrays)
export const isSearchRequestEmpty = (items: CargoSearchRequest): boolean =>
  Object.values(items).every(
    el => el === undefined || (Array.isArray(el) && el.length === 0));

// Get from worksheet additional properties, except given, with value mapped to JSON
export const getAdditionalPropsExceptKey = (key: string, additionalSearchProperties: WorksheetMetaProps[]): KeyValue[] =>
  additionalSearchProperties?.filter((f: WorksheetMetaProps) => f.key !== key).map(
    (f: WorksheetMetaProps): KeyValue => ({ key: f.key as string, value: typeof f.value === 'string' ? f.value : JSON.stringify(f.value) }));

// Get array of additional properties for saving in worksheet
export const getAdditionalPropsParsed = (key: string, data: unknown, additionalSearchProperties?: WorksheetMetaProps[]): KeyValue[] => {
  if (!additionalSearchProperties) {
    return [];
  }

  return data ?
    [...getAdditionalPropsExceptKey(key, additionalSearchProperties), {key, value: JSON.stringify(data)}] :
    getAdditionalPropsExceptKey(key, additionalSearchProperties);
};

// Parse additional properties to be saved as searchItems - [{key: 'a', value: 'b'}, {key: 'c', value: 'd'}] -> {a: 'b', c: 'd'}
export const getAdditionalPropsAsSearchItems = <T = CargoSearchRequest>(props: WorksheetMetaProps[]): T => props.reduce(
  (acc, prop) => {
    const { key, value } = prop;
    return { ...acc, [key as string]: value };
  },
  {} as T,
);

export const getAdditionalPropsFromSearchItems = (searchItems: SearchRequest): { key: string, value: string }[] => {
  return Object.entries(searchItems)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => ({
      key,
      value: typeof value === 'string' ? value : JSON.stringify(value),
    }));
};
