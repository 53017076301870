import { useMemo, useRef, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { clsx} from 'clsx';

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { getValueByPath } from 'helpers/Utils/misc';
import { getAllFromBitwise } from 'helpers/Utils/enum';
import { useExternalLink } from 'helpers/Hooks/ExternalLink';

import type { AuditEntityItemDetailed, AuditSnapshot, AuditVesselItemDetails } from 'modules/ShippingFixtures/Models/ShippingFixtures';
import { StausBadge } from 'modules/ShippingFixtures/Tempaltes/SanctionItemStatus';
import { AuditScoreType } from 'modules/ShippingFixtures/Models/Enums';

import SanctionStatus from 'modules/Sanctions/Templates/SanctionStatus';

import Snapshot from '../SanctionSearchSnaphot';

type DetailTypes = AuditEntityItemDetailed | AuditVesselItemDetails

type ComponentProps<T extends DetailTypes> = {
  heading: string,
  item: T,
  fixtureid: string,
}

enum DefinitionRenderingOpt {
  DateTime = 1,
  Name = 2
}

import styles from './SanctionsSearchDetail.module.scss';

type SnapshotProps = {
  snapshot: AuditSnapshot;
  fixtureid: string;
}

const AuditSnapShot = ({snapshot, fixtureid}: SnapshotProps) => {
  

  const loaderbar = useRef<HTMLElement>(null);

  const [ ready, setReady ] = useState<boolean>();

  useEffect(() => {
    if (!loaderbar.current) return;
    setReady(true);
  }, [loaderbar])

  const definitions = useMemo<[string, string, DefinitionRenderingOpt?][]>(() => {
    return [
      ['Search Run By', 'searchBy', DefinitionRenderingOpt.Name],
      ['When', 'when', DefinitionRenderingOpt.DateTime]
    ]
  }, [snapshot])

  const renderDefinition = (def: [string, string, DefinitionRenderingOpt?]): JSX.Element => {
    const [key, value, type ] = def;

    switch(type) {
      case DefinitionRenderingOpt.DateTime:
        return <>{ getValueByPath<AuditSnapshot, DateTime>(snapshot, value).toFormat('dd LLL yyyy hh:mm:ss') }</>
      case DefinitionRenderingOpt.Name:
      default:
        return <>{ getValueByPath<AuditSnapshot, string>(snapshot, value) }</>
    }
  }
  
  return <div className='direction--column position--relative'>
    <span ref={loaderbar} />
    { snapshot.score !== AuditScoreType.NoMatchingSearch &&
      <>
        <div className={clsx('definition-list__container', styles.definitions)}>
          { definitions.map(([key, value, type], index) =>
            <div key={index} className="definition-list__item">
              <dt>{ key }</dt>
              <dd>{ renderDefinition([key, value, type]) }
              </dd>
            </div>
          )}
        </div>
        { ready && 
          <Snapshot snapshotid={snapshot.snapshotId} fixtureid={fixtureid} target={loaderbar.current!} />
        }
      </>
    }
  </div>
}


const SanctionSearchDetail = <T extends DetailTypes, >({ heading, item, fixtureid }: ComponentProps<T>) => {
  
  const { snapshots } = item;
  
	const { openNewWindow } = useExternalLink();

	const handleViewCurrentClick = () => {
		switch(heading) {
			case "Vessel":
				openNewWindow(`/sanctions/vessel/${(item as AuditVesselItemDetails).imoNumber}`);
				break;
				default:
				openNewWindow(`/sanctions/entity/${item.value ?? item.name}`);
				break;
		}
	}

	const statuses = useMemo(() => {
    return getAllFromBitwise<AuditScoreType>(AuditScoreType, item.score)
  }, [item.score])

  return <>
    <header>
			<label>{ heading }</label>
			<Button 
				text 
				size='small'
				icon='iconoir-open-new-window icon--tiny'
				iconPos='right'
				className='lowercase'
				onClick={handleViewCurrentClick}
			>
				View Current
			</Button>
		</header>
    { !item.value && 
      <Message
        icon={<div className='iconoir-warning-triangle icon--large' />}
        text={
          <>
            There is <strong>no</strong> entity match for the {heading.toLowerCase()} {item.name}. in our sanctions lists. Possible invalid legal entity name. Trying to view the current sanction status using the above link is unlikely to display any apprioriate results
          </>
        }
      />
    }
		<div className={clsx(styles.risk, 'grow-to-fill')}>
			<label>Risk Level</label>
			<div role='cell'>{ SanctionStatus(item, {field: 'riskLevel'}) }</div>
			<label>Search Status</label>
			{ statuses.map((s) => 
				<StausBadge key={s} score={s} showLabel={true} itemType={heading} />
			)}
 		</div>
    { snapshots.length > 0 && snapshots.map( (ss) => (
      <AuditSnapShot snapshot={ss} fixtureid={fixtureid} key={ss.snapshotId} />
    ))}
  </>;
}

export default SanctionSearchDetail;
