import { clsx } from 'clsx';

import type { ColumnBodyOptions } from 'primereact/column';

import { getValueByPath } from 'helpers/Utils/misc';
import { getByNumber } from 'helpers/Utils/enum';
import { DoubleLineFlagged } from 'helpers/DataTable/Templates/ColumnTemplates/DoubleLineFlagged';

import { ShippingFixtureSummary, FixtureSearchAudit } from 'modules/ShippingFixtures/Models/ShippingFixtures';
import { AuditScoreType } from 'modules/ShippingFixtures/Models/Enums';

import styles from './Cell.module.scss';
import general from 'helpers/DataTable/Templates/ColumnTemplates/General.module.scss';

type CellRenderingProps = {
	fixture: ShippingFixtureSummary
	config: ColumnBodyOptions
	type: SanctionItemType
}

export enum SanctionItemType {
	Entity = 0,
	Vessel = 1
}

type BadgeRenderingProps = {
	score: AuditScoreType,
	itemType?: string,
	showLabel?: boolean;
}

export const StausBadge = ({ score, showLabel, itemType }: BadgeRenderingProps) => {

	// const { score, showLabel, entityType } = props;

	const icons = {
		[AuditScoreType.OK]: 'check',
		[AuditScoreType.NonFixingBrokerSearch]: 'user-x-mark',
		[AuditScoreType.NoMatchingSearch]: 'xmark',
		[AuditScoreType.ShowsRisk]: 'shield-alert',
		[AuditScoreType.Unknown]: 'unknown',
	}
	
	return <div 
		className={
			clsx( styles.status, 
				styles[getByNumber(AuditScoreType, score)], 
				`iconoir-${icons[score]}`,
				showLabel && styles.filled
			)}
		>{ showLabel && 
			<>
				{ score === AuditScoreType.ShowsRisk && `${itemType} ` }
				{ getByNumber(AuditScoreType, score, true) }
			</>
		}
	</div>
}

export const SanctionedItem = (props: CellRenderingProps) => {

	const { fixture, config, type } = props;

	let content: JSX.Element;

	const item = getValueByPath<ShippingFixtureSummary, FixtureSearchAudit>(fixture, config.field)!;

	switch(type) {
		case SanctionItemType.Vessel:
			/** Vessel type content should use the DoublrLineFlagged cell renderer */
			content = DoubleLineFlagged(item, {...config, field: 'name,imoNumber,flag' }, { hideSecondLineOnSmallScreens: true } );
			break;

		case SanctionItemType.Entity:
		default:
			content = <>{ item.name }</>;

	}

	return <>
		<div className={clsx('overflow--hidden', styles.cell)}>
			<div className={clsx('direction--column grow-to-fill', styles.bb, 'overflow--hidden')}>
				<label className={ general.label }>{ config.column.props.header as string }</label>
				<span>{ content }</span>
			</div>
			<StausBadge score={item.majorStatus} /></div>
	</>
}


// export default SanctionedItem;